import React from 'react';
import Section from '../components/Section';
import Metadata from '../components/Metadata';

function dbt() {
  return (
    <>
      <Metadata title='DBT' />
      <Section heading='Dialektisch-Behaviorale Therapie (DBT)'>
        <p>
          Die klassische dialektisch-behaviorale Therapie nach Linehan
          vermittelt Fertigkeiten (sogenannte Skills) zur Affektregulation: Es
          geht dabei um eine selbstbestimmte Bewältigung von besonders
          intensiven Gefühlen und Erregungszuständen, aber auch um die Kontrolle
          daraus resultierender, (unerwünschter) Handlungsimpulse.
        </p>
        <p>
          Die jüngere Version von DBT nach Lynch (RO-DBT) widmet sich im
          Vergleich dazu am anderen Ende des Spektrums typischen
          Verhaltensweisen der Überkontrolle (u.a. gehemmter emotionaler
          Ausdruck, Hyperfokus auf Details, übervorsichtiges Verhalten, strikte
          Regelorientierung und distanzierte Gestaltung sozialer Beziehungen).
          Die Fertigkeiten, die hier trainiert werden, zielen z.B. auf das
          Setzen bewusster sozialer Signale (social signaling).
        </p>
        <p>
          DBT versucht an beiden Enden des Verhaltensspektrums bisher
          unkontrollierte innere Prozesse beeinflussbarer zu machen. Sie ist als
          Verfahren gut erforscht und in ihrer Wirksamkeit vielfach belegt. Wir
          wenden Elemente daraus in der Einzeltherapie an.
        </p>
      </Section>
    </>
  );
}

export default dbt;
